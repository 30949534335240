<template>
  <div class="banner">
    <img
      class="tall"
      v-if="type === 'tall'"
      src="../assets/banner.webp"
      alt=""
    />
    <img
      class="middle"
      v-else-if="type === 'middle'"
      src="../assets/banner-middle.webp"
      alt=""
    />
    <img
      class="short"
      v-else-if="type === 'short'"
      src="../assets/banner-short.webp"
      alt=""
    />
    <img
      class="short"
      v-else-if="type === 'short2'"
      src="../assets/banner-short2.webp"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    type: {
      type: String,
      default() {
        return "tall";
      },
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.banner {
  .tall {
    width: 100%;
    height: 800px;
  }
  .short {
    width: 100%;
    height: 280px;
  }
  .middle {
    width: 100%;
    height: 429px;
  }
}
</style>
