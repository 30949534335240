<template>
  <div class="scheme">
    <Banner />
    <section class="platform">
      <span>智数云平台</span>
      <img src="../../assets/scheme/platform.webp" alt="" />
    </section>
    <section class="operation">
      <span>智慧运维</span>
      <feature-card
        class="fea-card"
        :heads="heads"
        :current="current"
        @tabChange="(index) => (current = index)"
      >
        <template v-slot:body>
          <img
            v-if="current == 0"
            src="../../assets/scheme/operation.webp"
            alt=""
            style="width: 981px; height: 164px"
          />
          <img
            v-else-if="current == 1"
            style="width: 924px; height: 141px"
            src="../../assets/scheme/warn.webp"
            alt=""
          />
          <img
            v-else-if="current == 2"
            style="width: 936px; height: 406px; margin: 45px 0 38px 0"
            src="../../assets/scheme/robot.webp"
            alt=""
          />
          <img
            v-else-if="current == 3"
            style="width: 952px; height: 391px; margin: 45px 0 48px 0"
            src="../../assets/scheme/pad.webp"
            alt=""
          />
        </template>
      </feature-card>
    </section>
    <section class="energy">
      <span>智慧能源</span>
    </section>
    <section class="garden">
      <span>智慧园区</span>
      <img src="../../assets/scheme/garden.webp" alt="" />
    </section>
    <section class="barracks">
      <span>智慧军营</span>
      <img src="../../assets/scheme/barracks.webp" alt="" />
    </section>
    <section class="atmosphere">
      <span>智慧气象</span>
      <img src="../../assets/scheme/atmosphere.webp" alt="" />
    </section>
    <!-- <Subscribe /> -->
  </div>
</template>

<script>
import Subscribe from "../../components/subscribe.vue";
import Banner from "../../components/banner.vue";
import FeatureCard from "../../components/featureCard.vue";

const heads = [
  {
    icon: "icon-a-icondaxiaomobandefuben11-011",
    ch: "运维管理",
    eng: "Mocha ITOM",
  },
  {
    icon: "icon-a-icondaxiaomobandefuben11-041",
    ch: "告警监测",
    eng: "Alarm Monitor",
  },
  {
    icon: "icon-a-icondaxiaomobandefuben11-031",
    ch: "运维机器人",
    eng: "Operation robot",
  },
  {
    icon: "icon-a-icondaxiaomobandefuben11-021",
    ch: "移动端协同",
    eng: "Mobile collaboration",
  },
];

export default {
  name: "index",
  data() {
    return {
      heads,
      current: 0,
    };
  },
  components: {
    Subscribe,
    Banner,
    FeatureCard,
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.scheme {
  font-size: 40px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #1f2025;
  line-height: 56px;

  section {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .platform {
    height: 960px;
    background: #f8f9fb;

    span {
      margin-top: 35px;
      margin-bottom: 38px;
    }

    img {
      width: 1189px;
      height: 764px;
    }
  }

  .operation {
    span {
      margin-top: 38px;
      margin-bottom: 61px;
    }

    .fea-card {
      margin-bottom: 80px;
      ::v-deep .card-body {
        min-height: 308px;
        background: #f8f9fb;
      }
    }
  }

  .energy {
    height: 640px;
    background: url("../../assets/scheme/energy.webp") no-repeat;
    background-size: 100% 100%;

    span {
      margin-top: 40px;
      color: #ffffff;
    }
  }

  .garden {
    height: 895px;

    span {
      margin-top: 47px;
      margin-bottom: 77px;
    }

    img {
      width: 1134px;
      height: 615px;
    }
  }

  .barracks {
    background: #f8f9fb;

    span {
      margin-top: 59px;
      margin-bottom: 56px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .atmosphere {
    height: 928px;
    span {
      margin-top: 65px;
      margin-bottom: 55px;
    }

    img {
      width: 1195px;
      height: 582px;
    }
  }
}
</style>
