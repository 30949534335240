<template>
  <div class="cont-card">
    <div class="card-head">
      <slot name="head"></slot>
    </div>
    <div class="card-cont">
      <slot name="cont"></slot>
    </div>
    <div class="card-pic">
      <slot name="pic"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "contCard",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.cont-card {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    133deg,
    rgba(255, 255, 255, 0.38) 2%,
    rgba(255, 255, 255, 0.4) 98%
  );
  border-radius: 14px;
  border: 1px solid #dbe2ff;
  display: flex;
  flex-flow: column nowrap;

  .card-head {
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d0daf6;
    font-size: 22px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #545454;
    line-height: 51px;
  }

  .card-cont {
    width: 100%;
    flex: 1;
    font-size: 17px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #545454;
    line-height: 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    ::v-deep span {
      margin-right: 42px;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #548eff;
        border: 1px solid #216cff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -9px;
        transform: translateY(-50%);
      }
    }
  }

  .card-pic {
    width: 100%;
    height: 130px;
    padding: 25px 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    background: #ffffff;
    border-radius: 0px 0px 17px 17px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    background: #fff;
  }
}
</style>
