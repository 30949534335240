<template>
  <div class="about">
    <Banner type="middle" />
    <section class="longkun">
      <span>关于龙坤</span>
    </section>
    <section class="history">
      <span>发展历程</span>
    </section>
    <section class="idea">
      <span>公司理念</span>
    </section>
    <section class="glory">
      <span>荣誉资质</span>
      <span>
        国家高新技术企业、双软企业、CMMI3认证、JQB9001、ISO9001、ISO14001、OHSAS18001、ISO/IEC27001、ISO/IEC20000、3C/CE认证……
      </span>
      <img src="../../assets/about/glory.webp" alt="" />
    </section>
    <!-- <Subscribe /> -->
  </div>
</template>

<script>
import Subscribe from "../../components/subscribe.vue";
import Banner from "../../components/banner.vue";

export default {
  name: "index",
  data() {
    return {};
  },
  components: {
    Subscribe,
    Banner,
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.about {
  section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    span {
      font-size: 40px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #1f2025;
      line-height: 56px;
      margin-top: 22px;
    }
  }

  .longkun {
    height: 855px;
    background: url("../../assets/about/about.png") no-repeat;
    background-size: 100% 100%;
  }

  .history {
    height: 963px;
    background: url("../../assets/about/history.webp") no-repeat;
    background-size: 100% 100%;

    span {
      margin-top: 60px;
    }
  }

  .idea {
    height: 970px;
    background: url("../../assets/about/idea.webp") no-repeat;
    background-size: 100% 100%;

    span {
      margin-top: 60px;
    }
  }

  .glory {
    height: 1408px;

    > img {
      height: 983px;
      width: 1370px;
    }

    span:last-of-type {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(43, 43, 43, 0.9);
      line-height: 25px;
      margin-top: 82px;
      margin-bottom: 12px;
    }
  }
}
</style>
