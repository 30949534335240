<template>
  <div class="case-card" :style="{ backgroundImage: `url(${img})` }">
    <div class="describe">
      <p v-for="(item, index) in describe" :key="index">{{ item }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "caseCard",
  props: {
    img: {
      type: String,
      default() {
        return "";
      },
    },
    describe: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.case-card {
  width: 1440px;
  height: 540px;
  margin-bottom: 24px;

  background-size: 100% 100%;

  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #394869;
  line-height: 40px;

  .describe {
    width: 36.12%;
    max-height: 240px;
    overflow-y: scroll;
    margin-top: 14.58%;
    margin-left: 51.18%;

    p {
      margin-bottom: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
