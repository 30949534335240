import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import First from '../views/first/index.vue'
import Product from '../views/product/index.vue'
import Scheme from '../views/scheme/index.vue'
import Case from '../views/case/index.vue'
import About from '../views/about/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/index',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'index',
        name: 'first',
        component: First
      },
      {
        path: 'product',
        name: 'product',
        component: Product
      },
      {
        path: 'scheme',
        name: 'scheme',
        component: Scheme
      },
      {
        path: 'case',
        name: 'case',
        component: Case
      },
      {
        path: 'about',
        name: 'about',
        component: About
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
