<template>
  <div class="case">
    <Banner type="short2" />
    <section>
      <span>部分客户案例</span>
      <case-card v-for="(item, index) in imgs" :key="index" :img="item.img" :describe="item.text"></case-card>
    </section>
    <section class="friends">
      <span>合作伙伴</span>
      <img src="../../assets/case/friends.png" alt="" />
    </section>
    <!-- <Subscribe /> -->
  </div>
</template>

<script>
import Subscribe from "../../components/subscribe.vue";
import Banner from "../../components/banner.vue";
import CaseCard from "./components/caseCard.vue";

const imgs = [
  {
    img: require("../../assets/case/guowang.webp"),
    text: [
      "国网信产集团边缘计算运营综合管理平台，包括80多个功能模块，实现数据的分权分域，有效展示和管理。2019年实现了对全国18个省、136个站点进行微模块机房基础设施监控；2020年实现了对全国27个省、827个站点进行微模块机房基础设施监控，预期2021接入站点规模将超过2000个。",
    ],
  },
  {
    img: require("../../assets/case/guianyidong.webp"),
    text: [
      "贵安数据中心整体园区统一基础设施智能管理平台的建设，既建设二期，同时又有效整合一期系统， 实现整个园区各运维管理维度、管理单位以及业务支持的集中化、系统化、一体化平台建设支撑，并通过权限划分，场景及视图化适配实现多部门的统一使用及呈现。",
    ],
  },
  {
    img: require("../../assets/case/xiongan.webp"),
    text: [
      `由建筑设计院自主原创并提供全过程工程咨询服务的雄安超算中心项目，位于雄安新区容城县容东片区西侧生态绿地公园区域悦容公园内，作为雄安新区的智慧大脑，承载着城市计算及灾害处理等重大任务，项目定位为"雄安数字城市之眼、雄安智能城市之脑、雄安生态城市之芯”，主体建筑满足近零能耗建筑标准，达到国际领先水平。项目延续"山形水系，阴阳和合”的设计理念，将建筑主体隐于自然之中，延续景观整体的山水脉络，与环境融为一体。建筑正立面形象呼应数字孪生城市的理念，展现雄安数字城市之眼。项目建成后将成为全国范围内第一个集城市计算、超算和近零能耗建筑于一体的大型智慧建筑。`,
    ],
  },
  {
    img: require("../../assets/case/lianrun.webp"),
    text: [
      `本工程为中国电信河北分公司2021年联润机楼机电配套工程（一期）设计项目。本次主要采购内容为联润机楼机电配套工程智能化系统（DCIM系统、园区安防系统、视频监控系统）。以适度的超前意识为指导原则，采用先进的系统设备及系统软件和开发工具，保证系统在技术上领先，成熟稳定，符合今后的发展趋势。`,
    ],
  },
  {
    img: require("../../assets/case/nanxiang.webp"),
    text: [
      `南翔云数据中心参考T3+标准建设，重要设施节点2N冗余，数据中心为专用建筑， 已与中国移动广州公司签订背靠背合作协议，数据中心由两个不同变电站分别供电，双路市电接入，用电可靠。防火等级一级 ，建筑抗震强度为8级，楼面载荷1000KG/M²，局部加固到1500KG/M²。`,
    ],
  },
  // {
  //   img: require("../../assets/case/runze.webp"),
  //   text: [
  //     `润泽国际信息港，位于河北廊坊市经济技术开发区，是河北省十二五规划重点产业项目。项目规划总建筑面积262万平方米，其中数据中心机房建筑面积约66万平方米。`,
  //     `本次建设的A-5数据中心，总建筑面积3.3万平方米，施工范围包含M1、M2、M4、M5模组。`,
  //   ],
  // },
  // {
  //   img: require("../../assets/case/pengboshi.webp"),
  //   text: [
  //     `鹏博士广州云计算中心，位于广州市经济技术开发区，包括2栋建筑，总建筑面积约1.5万㎡，是目前广东省范围内规模较大、档次最高的数据中心之一，为用户提供互联互通的网络平台。`,
  //   ],
  // },
  // {
  //   img: require("../../assets/case/linkong.webp"),
  //   text: [
  //     `武汉众维亿方大数据科技有限公司“临空港”数据中心项目2018年5月在武汉正式启动，该项目总投资130亿元，将打造华中地区最大的国际5A数据中心。该项目响应湖北省政府和武汉市政府的互联网“大链接”战略，建成后将成为华中区最大的互联网数据中心，加快智慧城市基础设施升级，满足临空港乃至武汉市、华中区最严苛的网络数据服务要求。项目分两期建设，占地面积13.3万平方米，预计五年内全部建成，将拥有13000个机柜，承载15万台服务器。在临空港开发区、武汉电信及其他合作伙伴的支持帮助下，该项目实现了当年签约、当年动工、当年投产、当年见效。`,
  //   ],
  // },
  // {
  //   img: require("../../assets/case/panjin.webp"),
  //   text: [
  //     `盘锦市大数据整合及应用服务平台项目，位于辽宁省盘锦市辽东湾新区，一期机房及配套区域面积约2000㎡。平台落成后，将为盘锦全市提供数据交换共享、数据管理与监控、数据应用等服务，实现多个部门信息共享交换的集中和分布式管理应用，可谓是盘锦市的重大惠民工程。`,
  //   ],
  // },
  // {
  //   img: require("../../assets/case/beijingbank.webp"),
  //   text: [
  //     `北京银行顺义科技研发中心，位于顺义区新城第11街区，总建筑面积37.4万平方米，包括办公楼、培训中心、研发中心、数据中心等。该中心是北京银行非常具有前瞻性的重要举措，单体面积为亚洲最大，可满足北京银行未来50年发展需要。项目建成正式运营后预计年税收13亿元。龙坤软件为北京银行提供机房动环监控系统（DCMS）产品及技术服务，以此为北京银行的科技研发、生产运行等工作保驾护航。`,
  //   ],
  // },
  // {
  //   img: require("../../assets/case/huaxiabank.webp"),
  //   text: [
  //     `华夏银行信用卡中心，机房位于北京石景山区鲁谷银河商务区F地块中惠大厦十层，机房按《电子信息系统机房设计规范》及《华夏银行信息系统机房建设技术规范》中B级机房标准设计，机房区域包括主机房、通信间、运营商接入机房、配电间、电池间、机房设备测试维修间等，工程建筑面积合计约1200平米。由龙坤软件为华夏银行信用卡中心提供动环监控系统（DCMS）产品及技术服务。同时，分布在北京、上海、重庆、南京等多个省市的多个下属机房，统一由龙坤软件提供动环监控系统并接入华夏银行信用卡中心监控中心，实现联网监控统一管理。`,
  //   ],
  // },
];

export default {
  name: "index",
  data() {
    return {
      imgs,
    };
  },
  components: {
    Subscribe,
    Banner,
    CaseCard,
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.case {
  section {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: #f8f9fb;
    > span {
      font-size: 40px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #1f2025;
      line-height: 56px;
      margin-top: 74px;
      margin-bottom: 56px;
    }

    // > img {
    //   width: 1440px;
    //   height: 540px;
    //   margin-bottom: 24px;
    // }
  }

  .friends {
    background: #fff;
    padding-bottom: 70px;

    span {
      margin-top: 103px;
      margin-bottom: 88px;
    }

    img {
      width: 1231px;
      height: 810px;
    }
  }
}
</style>
