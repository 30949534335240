<template>
  <div class="subscribe">
    <span>预约演示,获取尊享服务</span>
    <span>预约产品和方案演示,提供专属于您的服务</span>
    <div class="btn">
      <div class="icon iconfont icon-ic_yunweiguanli"></div>
      预约演示
    </div>
  </div>
</template>

<script>
export default {
  name: "subscribe",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.subscribe {
  width: 100%;
  height: 540px;
  background: url("../assets/first/subscribe.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  > span {
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    line-height: 75px;
    &:nth-child(1) {
      font-size: 64px;
      color: #1f2025;
      margin-top: 127px;
    }
    &:nth-child(2) {
      font-size: 26px;
      color: #545454;
      margin-top: 28px;
    }
  }

  .btn {
    margin-top: 56px;
    cursor: pointer;
    width: 288px;
    height: 72px;
    background: rgba(255, 255, 255, 0.66);
    border: 2px solid #216cff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 26px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #216cff;
    line-height: 37px;

    .iconfont {
      width: auto;
      height: auto;
      font-size: 26px;
      position: relative;
      top: 2px;
      margin-right: 12px;
    }
  }
}
</style>
