<template>
  <div class="home">
    <header>
      <a-tabs @change="changeTab" v-model="current">
        <a-tab-pane key="index" tab="首页"></a-tab-pane>
        <a-tab-pane key="product" tab="产品中心"></a-tab-pane>
        <a-tab-pane key="scheme" tab="解决方案"></a-tab-pane>
        <a-tab-pane key="case" tab="客户案例"></a-tab-pane>
        <a-tab-pane key="about" tab="关于龙坤"></a-tab-pane>
      </a-tabs>
    </header>
    <article>
      <router-view />
    </article>
    <footer>
      <span @click="jump"
        >COPYRIGHT &copy; 龙坤（无锡）智慧科技有限公司 ALL RIGHTS RESERVED.
        苏ICP备2021014966号-1</span
      >
    </footer>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      current: "index",
      top: 0,
    };
  },
  components: {},
  created() {
    this.$router.push("/");
  },
  methods: {
    changeTab(e) {
      this.current = e;
      this.$router.push(`/home/${e}`);
    },
    jump() {
      window.location.href = "https://beian.miit.gov.cn";
    },
  },
};
</script>

<style lang="less">
.home {
  width: 100%;
  height: 100%;
  user-select: none;

  header {
    width: 100%;
    height: 88px;
    background: #fff url("../assets/logo.webp") 20.72% center no-repeat;
    background-size: 100px 40px;

    .ant-tabs {
      margin-left: 45%;

      .ant-tabs-bar {
        margin: 0;
        .ant-tabs-tab {
          padding: 30px 0;
          margin-right: 70px;
          font-size: 20px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #2a2a2a;
          line-height: 28px;
        }

        .ant-tabs-tab-active {
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #446bfd;
        }
      }
    }
  }

  article {
  }

  footer {
    width: 100%;
    height: 60px;
    background: #050f2a;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 20px;
      font-family: SourceHanSansCN, SourceHanSansCN-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
