<template>
  <div class="feature-card">
    <div class="card-head">
      <div
        :class="[{ active: current === index }]"
        v-for="(item, index) in heads"
        :key="index"
        @click="$emit('tabChange', index)"
      >
        <div :class="['icon', 'iconfont', item.icon]"></div>
        <div class="text">
          <span>{{ item.ch }}</span>
          <span>{{ item.eng }}</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "featureCard",
  props: {
    heads: {
      type: Array,
      default() {
        return [];
      },
    },
    current: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.feature-card {
  width: 1180px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 4px 20px 0px rgba(69, 76, 117, 0.11);

  .card-head {
    height: 119px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e3e3e3;

    > div {
      flex: 1;
      border-right: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .icon {
        width: 70px;
        height: 70px;
        border: 1px solid #989dab;
        border-radius: 50%;
        box-shadow: 0px 5px 6px 0px rgba(221, 221, 221, 0.4);
        font-size: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .text {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-content: space-between;
        margin-left: 16px;

        span {
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #3b455c;
          line-height: 16px;

          &:first-child {
            font-size: 20px;
          }
          &:last-child {
            font-size: 12px;
            margin-top: 9px;
          }
        }
      }

      &:last-child {
        border: none;
      }
    }

    .active {
      background: linear-gradient(296deg, #66baff 9%, #2033ff 92%);
      .icon {
        background: #2274ff;
        color: #fff;
        border: 1px solid #07fbff;
        box-shadow: 0px 5px 6px 0px rgba(4, 75, 199, 0.4);
      }
      .text > span {
        color: #fff;
      }
    }
  }

  .card-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
