<template>
  <div class="product">
    <Banner type="short" />
    <section>
      <span>产品特点</span>
      <feature-card
        class="fea-card"
        :heads="heads"
        :current="current"
        @tabChange="(index) => (current = index)"
      >
        <template v-slot:body>
          <img
            v-if="current == 0"
            src="../../assets/product/ai.webp"
            alt=""
            style="width: 1018px; height: 480px"
          />
          <img
            v-else-if="current == 1"
            style="width: 1073px; height: 489px"
            src="../../assets/product/shuzi.webp"
            alt=""
          />
          <div v-else-if="current == 2">
            <img
              v-if="alot == 0"
              style="width: 956px; height: 430px"
              src="../../assets/product/ALoT1.webp"
              alt=""
            />
            <img
              v-else-if="(alot = 1)"
              style="width: 956px; height: 430px"
              src="../../assets/product/ALoT2.webp"
              alt=""
            />
            <div class="itembars">
              <div @click="alot = 0" :class="[{ active: alot == 0 }]"></div>
              <div @click="alot = 1" :class="[{ active: alot == 1 }]"></div>
            </div>
          </div>
          <img
            v-else-if="current == 3"
            style="width: 1071px; height: 479px"
            src="../../assets/product/guochan.webp"
            alt=""
          />
        </template>
      </feature-card>
    </section>
    <img src="../../assets/product/city-back.webp" alt="" />
    <!-- <Subscribe /> -->
  </div>
</template>

<script>
import Subscribe from "../../components/subscribe.vue";
import Banner from "../../components/banner.vue";
import FeatureCard from "../../components/featureCard.vue";

const heads = [
  {
    icon: "icon-a-dashujuAI",
    ch: "大数据&AI",
    eng: "Big data & Al",
  },
  {
    icon: "icon-shuziluansheng",
    ch: "数字孪生",
    eng: "Digital Twin",
  },
  {
    icon: "icon-AIOT",
    ch: "AIOT网关",
    eng: "AIOT Gateway",
  },
  {
    icon: "icon-guochanhua",
    ch: "支持国产化",
    eng: "Support Localization",
  },
];

export default {
  name: "index",
  data() {
    return {
      heads,
      current: 0,
      alot: 0,
    };
  },
  components: { Subscribe, Banner, FeatureCard },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.product {
  section {
    height: 650px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    > img {
      width: 1015px;
      height: 703px;
      margin-top: 69px;
    }
    > span {
      font-size: 40px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 40px;
      margin-top: 96px;
    }

    .fea-card {
      position: relative;
      top: 52px;
      ::v-deep .card-body {
        height: 577px;
      }

      .itembars {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        div {
          width: 8px;
          height: 6px;
          background: #050b17;
          border-radius: 2px;
          margin-right: 12px;
          cursor: pointer;
        }
        .active {
          width: 18px;
        }
      }
    }
  }

  > img {
    height: 440px;
    width: 100%;
  }
}
</style>
