<template>
  <div class="func-card">
    <slot name="icon"></slot>
    <span class="text"><slot name="text"></slot></span>
  </div>
</template>

<script>
export default {
  name: "funcCard",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.func-card {
  width: 100%;
  height: 100%;
  border: 1px solid #c7cce3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 28px;
    height: 28px;
  }

  .text {
    margin-left: 6px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    line-height: 22px;
  }
}
</style>
