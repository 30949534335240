<template>
  <div class="first">
    <Banner />
    <div class="plan-contain">
      <span>方案涵盖</span>
      <div class="cont-cards">
        <div class="left">
          <cont-card>
            <div slot="head">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-icondaxiaomobandefuben11-05"></use>
              </svg>
              安防监控数字化-异常预警告警
            </div>
            <template v-slot:cont>
              <a-row>
                <a-col :span="5"></a-col>
                <span>设备故障告警</span>
                <span>出入门禁管理</span>
              </a-row>
              <a-row>
                <a-col :span="5"></a-col>
                <span>数据异常预警</span>
                <span>安全视频监控管理</span>
              </a-row>
              <a-row>
                <a-col :span="5"></a-col>
                <span>预警、告警功能帮助用户避免、解决运维事故</span></a-row
              >
            </template>
            <img src="../../assets/first/anfang.webp" alt="" slot="pic" />
          </cont-card>
          <cont-card>
            <div slot="head">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-icondaxiaomobandefuben11-01"></use></svg
              >资产价值数字化-资产容量管理
            </div>
            <template v-slot:cont>
              <a-row>
                <a-col :span="6"></a-col>
                <span>机房资产全生命周期管理</span>
              </a-row>
              <a-row>
                <a-col :span="6"></a-col>
                <span>机房机柜容量、智能规划、计划调优</span>
              </a-row>
              <a-row>
                <a-col :span="6"></a-col>
                <span>提升容量利用率、延长资产使用寿命</span></a-row
              >
            </template>
            <img src="../../assets/first/zichan.webp" alt="" slot="pic" />
          </cont-card>
          <cont-card>
            <div slot="head">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-icondaxiaomobandefuben11-02"></use>
              </svg>
              生产运营数字化-智慧运维能力
            </div>
            <template v-slot:cont>
              <a-row>
                <a-col :span="9"></a-col>
                <span>机器人智能巡检</span>
              </a-row>
              <a-row>
                <a-col :span="9"></a-col>
                <span>计划工单执行</span>
              </a-row>
            </template>
            <img src="../../assets/first/shengchan.webp" alt="" slot="pic" />
          </cont-card>
          <cont-card>
            <div slot="head">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-icondaxiaomobandefuben11-03"></use></svg
              >资源全景可视化-数据采集
            </div>
            <template v-slot:cont>
              <a-row>
                <a-col :span="2"></a-col>
                <span>动环数据采集</span>
                <span>远程联动策略</span>
                <span>设备视频监控管理</span>
              </a-row>
              <a-row>
                <a-col :span="2"></a-col>
                <span>生产资料数字化呈现，构建机房精细化模型动态数据交</span>
              </a-row>
              <a-row>
                <a-col :span="2"></a-col>
                <span>互，GIS（2D/3D）</span>
              </a-row>
            </template>
            <img src="../../assets/first/ziyuan.webp" alt="" slot="pic" />
          </cont-card>
        </div>
        <div class="right">
          <cont-card>
            <div slot="head">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-icondaxiaomobandefuben11-04"></use></svg
              >绿色节能数字化-机房绿色节能
            </div>
            <template v-slot:cont>
              <a-row>
                <a-col :span="7"></a-col>
                <span>海量数据累积</span>
              </a-row>
              <a-row>
                <a-col :span="7"></a-col>
                机房PUE预测算法
              </a-row>
            </template>
            <img src="../../assets/first/lvse.webp" alt="" slot="pic" />
          </cont-card>
        </div>
      </div>
    </div>
    <div class="plan-feature">
      <span>方案特点</span>
    </div>
    <div class="smart-xdc">
      <span>Smart·XDC</span>
      <div class="funcs">
        <func-card v-for="(item, index) in cards" :key="index">
          <template v-slot:icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.icon"></use>
            </svg>
          </template>
          <template v-slot:text>
            {{ item.label }}
          </template>
        </func-card>
      </div>
    </div>
    <!-- <Subscribe /> -->
  </div>
</template>

<script>
import ContCard from "./components/contCard.vue";
import FuncCard from "./components/funcCard.vue";
import Subscribe from "../../components/subscribe.vue";
import Banner from "../../components/banner.vue";

const cards = [
  {
    icon: "#icon-a_zichanguanli",
    label: "资产管理",
  },
  {
    icon: "#icon-a_nengxiaoguanli",
    label: "能效管理",
  },
  {
    icon: "#icon-a_rongliangguanli",
    label: "容量管理",
  },
  {
    icon: "#icon-a_lianjieguanli",
    label: "连接管理",
  },
  {
    icon: "#icon-a_shijianguanli",
    label: "事件管理",
  },
  {
    icon: "#icon-p_renyuanguanli",
    label: "人员管理",
  },
  {
    icon: "#icon-p_yujingguanli",
    label: "预警管理",
  },
  {
    icon: "#icon-p_gaojingguanli",
    label: "告警管理",
  },
  {
    icon: "#icon-p_zuhuguanli",
    label: "租户管理",
  },
  {
    icon: "#icon-p_yewuguanli",
    label: "业务管理",
  },
  {
    icon: "#icon-p_xunjianguanli",
    label: "巡检管理",
  },
  {
    icon: "#icon-p_weibaoguanli",
    label: "维保管理",
  },
  {
    icon: "#icon-p_biangengguanli",
    label: "变更管理",
  },
  {
    icon: "#icon-p_shishijiankong",
    label: "实时监控",
  },
  {
    icon: "#icon-p_zhishiguanli",
    label: "知识管理",
  },
  {
    icon: "#icon-c_kucunguanli",
    label: "库存管理",
  },
  {
    icon: "#icon-c_jihuaguanli",
    label: "计划管理",
  },
  {
    icon: "#icon-c_paibanguanli",
    label: "排班管理",
  },
  {
    icon: "#icon-c_yingjiguanli",
    label: "应急管理",
  },
  {
    icon: "#icon-c_baobiaofenxi",
    label: "报表分析",
  },
  {
    icon: "#icon-c_tongjifenxi",
    label: "统计分析",
  },
  {
    icon: "#icon-c_juecezhichi",
    label: "决策支持",
  },
  {
    icon: "#icon-c_gongyingshangguanli",
    label: "供应商管理",
  },
  {
    icon: "#icon-c_yunweiguanli",
    label: "运维管理",
  },
];

export default {
  name: "first",
  data() {
    return {
      cards,
    };
  },
  components: { ContCard, FuncCard, Subscribe, Banner },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.first {
  width: 100%;

  .plan-contain {
    width: 100%;
    height: 1098px;
    background: url("../../assets/plan-contain-back.webp") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    > span {
      font-size: 40px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #1f2025;
      line-height: 56px;
      margin-top: 76px;
    }

    .cont-cards {
      height: 720px;
      margin-top: 121px;
      display: flex;
      .left {
        width: 1026px;
        height: 100%;
        margin-right: 10px;
        display: flex;
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: space-between;
        > * {
          width: 508px;
          height: 355px;

          &:last-child {
            ::v-deep .card-cont {
              span {
                margin-right: 27px;
              }
              .ant-row:last-child {
                position: relative;
                top: -10px;
                span::before {
                  display: none;
                }
              }
            }
          }
        }
      }
      .right > * {
        width: 357px;
        height: 100%;
        ::v-deep .card-pic {
          height: 355px;
          padding: 46px 102px;
        }
      }
    }
  }

  .plan-feature {
    width: 100%;
    height: 836px;
    background: url("../../assets/first/feature.webp") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    span {
      font-size: 40px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 56px;
      margin-top: 66px;
    }
  }

  .smart-xdc {
    width: 100%;
    height: 748px;
    font-size: 40px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #1f2025;
    line-height: 56px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: url("../../assets/first/xdc.webp") 4.2% center no-repeat;
    background-size: 32% 75.53%;
    position: relative;

    > span {
      margin-top: 60px;
    }

    .funcs {
      width: 43%;
      position: absolute;
      top: 30.74%;
      left: 37.57%;
      display: flex;
      align-content: flex-start;
      flex-flow: row wrap;

      > * {
        width: 17.2%;
        height: 60px;
        margin: 0 20px 18px 0;
      }
    }
  }
}
</style>
